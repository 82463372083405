import React from "react";
import pick from "lodash/pick";
import { useRouter } from "next/router";

import useTranslations from "@/utils/useTranslations";

function NotFound() {
  const t = useTranslations("common");
  const router = useRouter();

  return (
    <>
      <h1>{t("notFound.title")}</h1>
      <div className="errorBox">
        <p>{t("notFound.desc")}</p>
        <button type="button" onClick={() => router.replace("/")}>
          {t("backToHome")}
        </button>
      </div>
    </>
  );
}

export async function getStaticProps({ locale }) {
  return {
    props: {
      messages: pick((await import(`locale/${locale}.json`)).default, [
        "common",
      ]),
    },
  };
}

export default NotFound;
